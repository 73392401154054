/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import section1Img from 'static/welfare/section5_1.png';
import logoBlck from 'static/common/logo-black.png';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Section5 = () => {
  const { mq } = useTheme();
  const navigation = useNavigate();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section5Style = css`
    padding: 160px 0px;
    .container {
      position: relative;
      z-index: 1;
      max-width: 1480px;
      padding: 0 60px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .text {
        .logo {
          img {
            width: 108px;
          }
        }
        .title {
          font-size: 36px;
          margin-top: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          color: #000;
          span {
            color: #72aed3;
          }
        }
        .desc {
          color: #6c737f;
          font-size: 20px;
          line-height: 150%;
          margin-top: 48px;
          strong {
            font-weight: 700;
          }
        }
        button {
          border-radius: 999px;
          border: 0;
          margin-top: 48px;
          background: #72aed3;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          color: #fff;
          padding: 18px 38px 18px 45px;
          display: flex;
          cursor: pointer;
          align-items: center;
          gap: 8px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .img {
        img {
          width: 600px;
          margin-right: -50px;
        }
      }
    }
    ${mq.mobile} {
      padding: 84px 0px;
      .container {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
        .text {
          .logo {
            img {
              width: 64px;
            }
          }
          .title {
            font-size: 24px;
            margin-top: 8px;
            line-height: 32px;
          }
          .desc {
            color: #6c737f;
            font-size: 15px;
            line-height: 22px;
            margin-top: 20px;
            br {
              display: none;
            }
          }
        }
        .img {
          margin-top: 20px;
          img {
            width: 100%;
          }
        }
        button {
          border-radius: 999px;
          border: 0;
          margin-top: 24px;
          background: #72aed3;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: #fff;
          padding: 12px 32px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          gap: 8px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  `;

  const yVariants = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <div css={Section5Style}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={yVariants}
        className="container"
      >
        <div className="text">
          <h1 className="logo">
            <img src={logoBlck} alt="로고 | 이미지" title="로고" />
          </h1>
          <div className="title">
            일 방문 10만명이 이용 중인 <br />
            커머스 플랫폼, <span>몰파이</span>를 <br />
            <span>복지몰 서비스</span>로 만나보세요!
          </div>
          <div className="desc">
            몰파이는 국내 유일, 상품 결합형 쇼핑 플랫폼으로 <br />
            1천만 이상의 고객을 보유한 기업들이 몰파이를 활용해 쇼핑몰을 <br />
            운영하고 있습니다.{' '}
            <strong>
              축적된 커머스 역량과 노하우가 담긴
              <br />
              몰파이 커머스를
            </strong>{' '}
            우리 기업의 복지몰로 만나보세요!
          </div>
          {isPc && (
            <button onClick={() => navigation('/download')}>
              기업 소개서 받기
              <MainArrow />
            </button>
          )}
        </div>
        <div className="img">
          <img src={section1Img} alt="이미지" title="손쉬운 제작2" />
        </div>
        {isMobile && (
          <button onClick={() => navigation('/download')}>
            기업 소개서 받기
            <MainArrow />
          </button>
        )}
      </motion.div>
    </div>
  );
};

export default Section5;
