/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import { useUser } from 'core/store/context/UserContext';
import { useLogout } from 'core/queries/user';
import { useCookies } from 'react-cookie';
import { css, useTheme } from '@emotion/react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'static/common/logo.svg';
import { ReactComponent as Menu } from 'static/common/menu.svg';
import { ReactComponent as Close } from 'static/common/close.svg';
import { ReactComponent as ArrowLeft } from 'static/user/mypage/arrowLeft.svg';
import { ReactComponent as UserCircle } from 'static/home/user_circle.svg';
import { ReactComponent as UserCircleGear } from 'static/home/user_circle_gear.svg';
import { ReactComponent as UsersFour } from 'static/home/users_four.svg';
import { ReactComponent as Buildings } from 'static/home/buildings.svg';
import { ReactComponent as StoreFront } from 'static/home/Storefront.svg';
import { ReactComponent as Coins } from 'static/home/coins.svg';
import arrowUpBlack from 'static/common/arrow_up_black.svg';
import ArrowLineDown from 'static/common/arrow_line_down.svg';
import arrowDownBlack from 'static/common/arrow_down_black.svg';
import bizImg from 'static/home/biz.png';
import nowImg from 'static/home/now.png';

import Button from '../Button';
import Dropdown from '../Dropdown';
import { Mobile, PC } from '../Responsive';

interface HeaderProps {
  theme?: 'transparents' | 'black' | 'white';
  isBanner?: boolean;
}

const Header = ({ theme = 'black', isBanner }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUserData } = useUser();
  const { mainColor } = useTheme();
  const { mutate: logout } = useLogout();
  const [cookies, setCookie, removeCookie] = useCookies(['mallpie-user']);
  const [active, setActive] = useState<number | undefined>(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isTopScroll, setIsTopScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  const toggleSection = (section: string) => {
    setExpandedSections(prev =>
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section],
    );
  };
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (window.scrollY > 90) {
      if (scrollTop > lastScrollTop) {
        setIsScroll(true);
        setIsTopScroll(true);
      } else if (scrollTop < lastScrollTop) {
        setIsScroll(false);
      }
    } else {
      setIsTopScroll(false);
      setIsScroll(false);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const menuM = [
    {
      title: '서비스 소개',
      items: [
        {
          title: '상품 없이 누구나 만드는 쇼핑몰',
          items: [
            {
              text: '개인',
              desc: '누구나 만드는 나의 쇼핑몰',
              icon: <UserCircle />,
              href: '/intro/personal',
            },
            {
              text: '크리에이터',
              desc: '팬을 모으고 수익을 만드는 쇼핑몰',
              icon: <UserCircleGear />,
              href: '/intro/creator',
            },
            {
              text: '모임·단체',
              desc: '단체 구매가 더 쉬워지는 모임 쇼핑몰',
              icon: <UsersFour />,
              href: '/intro/class',
            },
            {
              text: '단계별 수익실현',
              desc: '한 눈에 보는 수익 실현 단계',
              icon: <Coins />,
              href: '/revenue',
            },
          ],
        },
        {
          title: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                기업 서비스{' '}
                <img alt="biz" src={bizImg} style={{ width: '31px' }} />
              </div>
              <Link
                to="/download"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  gap: '10px',
                }}
              >
                <img src={ArrowLineDown} alt="arrowDown" />

                <p>소개서 다운로드</p>
              </Link>
            </div>
          ),
          items: [
            {
              text: '자사 브랜드 쇼핑몰',
              desc: '쇼핑 서비스 도입을 위한 올인원 솔루션',
              icon: <Buildings />,
              href: 'https://mallpie.biz/intro/company',
            },
            {
              text: '임직원 복지몰',
              desc: '임직원, 담당자 모두가 만족하는 복지몰',
              icon: <StoreFront />,
              href: 'https://mallpie.biz/welfare',
            },
          ],
        },
      ],
    },
    { title: '기능', items: [], href: '/method' },
    {
      title: '블로그',
      items: [],
      href: 'https://blog.mallpie.kr',
    },
    { title: 'mallpieNOW', items: [], href: '/now' },
  ];

  const onLogout = async () => {
    await logout(undefined, {
      onSuccess: response => {
        if (response.code === 'SUCCESS') {
          removeCookie('mallpie-user', {
            path: '/',
            domain: process.env.REACT_APP_PUBLIC_URL,
          });
          setUserData(undefined);
          navigate('/');
        }
      },
      onError: (error: any) => {
        console.log(error);
      },
    });
  };

  const headerStyle = css`
    width: 100%;
    padding: 22px 3.15vw;
    left: 0;
    transition: top 0.6s;
    top: ${isScroll ? '-100%' : 0};
    position: fixed;
    z-index: 100;

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    .logo {
      font-size: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 108px;
      }
    }
    > .inner {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      display: flex;
      width: 100%;
      border-radius: 10vw;
      padding: 0 2vw;
      nav {
        margin-left: 5.2vw;
        > ul {
          height: 100%;
          display: flex;
          align-items: center;
        }
        > ul a {
          font-weight: 700;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          > img {
            margin-left: 2px;
          }
        }
        > ul > li {
          display: flex;
          align-items: center;
          position: relative;
          height: 100%;
          cursor: pointer;
          color: #000;
          margin: 0 18px;
          font-weight: 700;
          white-space: nowrap;
          &:hover {
            color: #6079ff;
          }
          &.active {
            color: ${mainColor};
            svg {
              stroke: ${mainColor};
            }
          }
          &.path {
            cursor: auto;
          }
          .active {
            color: ${mainColor};
          }

          .sub {
            position: absolute;
            padding: 20px 0px;
            color: #000;
            left: 0;
            width: 960px;
            height: 340px;

            .inner {
              background: #fff;
              display: flex;
              align-items: start;
              gap: 50px;
              box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
              border-radius: 30px;
              padding: 32px;

              .menuLayout {
                display: column;

                .menuTitle {
                  display: flex;
                  width: 350px;
                  justify-content: space-between;
                  .title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: #98a2b3;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                  }
                  .option {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    p {
                      color: #6c737f;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                    }
                  }
                }

                hr {
                  border: 0px;
                  border-top: 1px solid #f2f4f7;
                  margin-top: 20px;
                  margin-bottom: 20px;
                }

                .menuContent {
                  display: flex;
                  gap: 50px;
                  .subMenu {
                    .title {
                      font-size: 14px;
                      font-weight: 600;
                      color: #000000;
                    }

                    a {
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      &:hover {
                        .text {
                          .title {
                            color: #6079ff;
                          }
                        }
                      }
                      svg {
                        margin-right: 15px;
                      }
                      .text {
                        .title {
                          display: flex;
                          align-items: center;
                          font-size: 18px;
                          font-weight: 500;
                          line-height: 24px;
                          > img {
                            margin-left: 4px;
                          }
                        }
                        .desc {
                          color: #909090;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                        }
                      }
                    }
                    li + li {
                      margin-top: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .more {
        display: flex;
        font-size: 14px;
        margin-left: 8px;
        li {
          padding: 0 12px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          color: #000;
          cursor: pointer;
          &:hover {
            color: #6079ff;
          }
          &:nth-of-type(1) {
            &::before {
              content: '';
              margin-right: 26px;
              display: block;
              width: 1px;
              height: 14px;
              background: #000;
            }
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        margin-left: auto;
        .user {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
        }
        .logout {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: ${isScroll ? '#666' : theme !== 'white' ? '#fff' : '#666'};
          white-space: nowrap;
        }
        div {
          font-size: 14px;
          color: #000;
          margin-right: 36px;
          cursor: pointer;
          white-space: nowrap;
        }
        button {
          padding: 12px 50px;
          font-size: 16px;
          line-height: 23px;
          border: 0;
          background: #000;
          color: #fff;
          white-space: nowrap;
          border-radius: 30px;
          font-weight: 500;
          margin: 0.8vw 0vw;
          cursor: pointer;
          transition: background 0.2s;
          &.line {
            width: 160px;
            padding-left: 0;
            padding-right: 0;
            box-sizing: border-box;
            border: 1px solid #6079ff;
            color: #6079ff;
            background: #fff;
          }
          &:hover {
            background: #6079ff;
            color: #fff;
          }
        }
      }
    }
  `;

  const headerMoStyle = css`
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    box-shadow: ${isScroll ? '0px 4px 8px 0px rgba(0, 0, 0, 0.1)' : '0px'};
    background: ${lastScrollTop > 90 ? '#fff' : 'transparent'};
    transition:
      background-color 0.3s,
      box-shadow 0.3s,
      color 0.3s,
      top 0.3s;
    display: ${isBanner ? (isScroll ? 'flex' : 'flex') : 'flex'};
    align-items: center;
    position: ${isBanner ? (isScroll ? 'fixed' : 'absolute') : 'fixed'};
    left: 0;
    z-index: 100;
    .logo {
      font-size: 0;
      img {
        width: 88px;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    .user-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 16px;
          font-weight: 700;
          color: #6079ff;
        }
      }
      .logout {
        font-size: 16px;
        font-weight: 700;
        color: #666666;
        cursor: pointer;
      }
    }
    .icon {
      margin-left: auto;
    }
    .menu {
      width: 100%;
      height: 100%;
      position: fixed;
      transform: translateX(${isMenuVisible ? '0' : '100%'});
      transition: all 0.3s;
      left: 0;
      top: 0;
      background: #fff;
      overflow: auto;
      .header {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 12px 16px;
        align-items: center;
      }

      .content {
        height: calc(100% - 48px);
        overflow-y: auto;
        margin-top: 30px;
        hr {
          border: 0px;
          border-top: 1px solid #e5e7eb;
        }
        .menuWrapper {
          .section {
            h4 {
              font-size: 18px;
              cursor: pointer;
              color: black;
              font-weight: 700;
              margin: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              > a {
                padding: 20px 20px;
                display: block;
                width: 100%;
              }
            }

            ul {
              list-style: none;
              margin: 0;
              max-height: 0;
              overflow: hidden;
              background-color: #ffffff;

              &.expanded {
                max-height: 1000px;
                overflow: visible;
              }

              .subitem {
                .listWrapper > a {
                  font-size: 16px;
                  color: black;
                  font-weight: 500;
                  padding: 10px 32px 20px 32px;
                  display: flex;
                  gap: 20px;
                  align-items: center;
                  cursor: pointer;

                  .textWrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .title {
                      display: flex;
                      align-items: center;
                      font-size: 18px;
                      font-weight: 500;
                      color: black;
                      > img {
                        margin-left: 4px;
                      }
                    }
                    .desc {
                      font-size: 14px;
                      font-weight: 400;
                      color: #909090;
                    }
                  }
                }
                .subtitle {
                  padding-bottom: 20px;
                  hr {
                    border: 0px;
                    border-top: 1px solid #e5e7eb;
                    margin: 10px 20px;
                  }
                  .sub {
                    color: #98a2b3;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 20px 20px 10px 20px;
                  }
                }
              }
            }
          }
          .section_menu {
            h4 {
              padding: 20px 20px;
            }
          }
        }
        .info {
          padding: 20px;
          padding-bottom: 24px;
          button {
            width: 100%;
          }
          button + button {
            margin-top: 8px;
          }
        }
        .list {
          padding: 0 20px;
          > ul > li {
            padding: 24px 0px;

            .name {
              font-size: 16px;
              padding: 8px 0;
              font-weight: 600;
              line-height: 24px;
            }
            .sub {
              li {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                a {
                  padding: 8px 0px;
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  `;

  const onClickMenu = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      setIsMenuVisible(true);
    } else {
      setIsMenuVisible(false);
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 400);
    }
  }, [isMenuOpen]);

  return (
    <>
      <PC>
        <header css={headerStyle}>
          <div className="inner">
            <h1 className="logo">
              <Link to="/">
                <Logo fill="black" />
              </Link>
            </h1>
            <nav>
              <ul>
                <li className="path">
                  <Dropdown
                    title="서비스 소개"
                    index={1}
                    isActive={active}
                    color="black"
                  >
                    <div className="sub">
                      <div className="inner">
                        <div className="menuLayout">
                          <div className="menuTitle">
                            <p className="title">
                              상품 없이 누구나 만드는 쇼핑몰
                            </p>
                          </div>
                          <hr />
                          <div className="menuContent">
                            <div className="subMenu">
                              <ul>
                                <li>
                                  <Link to="/intro/personal">
                                    <UserCircle />
                                    <div className="text">
                                      <div className="title">개인</div>
                                      <div className="desc">
                                        누구나 만드는 나의 쇼핑몰
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/intro/creator">
                                    <UserCircleGear />
                                    <div className="text">
                                      <div className="title">크리에이터</div>
                                      <div className="desc">
                                        팬을 모으고 수익을 만드는 쇼핑몰
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/intro/class">
                                    <UsersFour />
                                    <div className="text">
                                      <div className="title">모임·단체</div>
                                      <div className="desc">
                                        단체 구매가 더 쉬워지는 모임 쇼핑몰
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="subMenu">
                              <ul>
                                <li>
                                  <Link to="/revenue">
                                    <Coins />
                                    <div className="text">
                                      <div className="title">
                                        단계별 수익실현
                                      </div>
                                      <div className="desc">
                                        한 눈에 보는 수익 실현 단계
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="menuLayout">
                          <div className="menuTitle ">
                            <p className="title">
                              기업 서비스{' '}
                              <img
                                alt="biz"
                                src={bizImg}
                                style={{ width: '31px' }}
                              />
                            </p>
                            <div
                              className="option"
                              style={{ cursor: 'pointer' }}
                            >
                              <Link to="https://mallpie.biz/download">
                                <img src={ArrowLineDown} alt="arrowDown" />

                                <p>소개서 다운로드</p>
                              </Link>
                            </div>
                          </div>
                          <hr />
                          <div className="menuContent">
                            <div className="subMenu">
                              <ul>
                                <li>
                                  <Link to="https://mallpie.biz/intro/company">
                                    <Buildings />
                                    <div className="text">
                                      <div className="title">
                                        자사 브랜드 쇼핑몰
                                      </div>
                                      <div className="desc">
                                        쇼핑 서비스 도입을 위한 올인원 솔루션
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://mallpie.biz/welfare">
                                    <StoreFront />
                                    <div className="text">
                                      <div className="title">임직원 복지몰</div>
                                      <div className="desc">
                                        임직원, 담당자 모두가 만족하는 복지몰
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </li>
                <li>
                  <Link to="/method" className="Link">
                    기능
                  </Link>
                </li>
                <li>
                  <a
                    href="https://blog.mallpie.kr"
                    rel="noopener noreferrer"
                    className="Link"
                  >
                    블로그
                  </a>
                </li>
                <li>
                  <Link to="/now" className="Link">
                    mallpie
                    <img alt="몰파이 | 이미지" title="몰파이" src={nowImg} />
                  </Link>
                </li>
              </ul>
            </nav>
            <ul className="more">
              <li>
                <Link to="/cs" className="Link">
                  고객 지원
                </Link>
              </li>
              <li>
                <Link to="/store" className="Link">
                  입점 센터
                </Link>
              </li>
            </ul>
            <div className="right">
              {/* {user ? (
                <>
                  <div
                    className="user"
                    onClick={() => navigate('/user/mypage/mymall')}
                  >
                    {user?.userNm}님
                    <ArrowLeft
                      stroke={
                        isScroll
                          ? mainColor
                          : theme !== 'white'
                          ? '#fff'
                          : mainColor
                      }
                    />
                  </div>
                  <div className="logout" onClick={onLogout}>
                    로그아웃
                  </div>
                </>
              ) : (
                <div onClick={() => navigate('/user/login')}>로그인</div>
              )} */}
              <a
                href="https://my.mallpie.kr"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px' }}
              >
                <button className="line">관리자 로그인</button>
              </a>
              <a
                href="https://my.mallpie.kr"
                target="_blank"
                rel="noopener noreferrer"
                id="top_menu_bu_mymall"
              >
                <button color="black">몰 만들기</button>
              </a>
            </div>
          </div>
        </header>
      </PC>
      <Mobile>
        <header css={headerMoStyle}>
          <h1 className="logo">
            <Link to="/">
              <Logo
                fill={
                  isTopScroll ? 'black' : theme !== 'white' ? 'white' : 'black'
                }
              />
            </Link>
          </h1>
          <div className="icon" onClick={onClickMenu}>
            <Menu
              stroke={
                isTopScroll ? 'black' : theme !== 'white' ? 'white' : 'black'
              }
            />
          </div>
          <div className="menu">
            <div className="header">
              <h1 className="logo">
                <Link to="/">
                  <Logo fill="black" />
                </Link>
              </h1>

              <div className="icon" onClick={onClickMenu}>
                <Close stroke={mainColor} />
              </div>
            </div>
            <nav>
              <div className="content">
                <div className="menuWrapper">
                  {menuM.map(section => (
                    <div
                      key={section.title}
                      className={
                        section.items.length > 0
                          ? 'section section_menu'
                          : 'section'
                      }
                    >
                      {section.items.length > 0 ? (
                        <>
                          <h4 onClick={() => toggleSection(section.title)}>
                            {section.title}
                            <span>
                              {expandedSections.includes(section.title) ? (
                                <img src={arrowUpBlack} alt="arrowUp" />
                              ) : (
                                <img src={arrowDownBlack} alt="arrowUp" />
                              )}
                            </span>
                          </h4>
                          <ul
                            className={
                              expandedSections.includes(section.title)
                                ? 'expanded'
                                : ''
                            }
                          >
                            <div className="subitem">
                              {section.items.map(item => (
                                <div key={item.title} className="subtitle">
                                  <li className="sub">{item.title}</li>
                                  <hr />
                                  {item.items.map(item => (
                                    <div
                                      className="listWrapper"
                                      key={item.text}
                                    >
                                      <Link to={`${item.href}`}>
                                        {item.icon}
                                        <div className="textWrapper">
                                          <p className="title">{item.text}</p>
                                          <p className="desc">{item.desc}</p>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </ul>
                        </>
                      ) : (
                        <h4>
                          <Link
                            to={`${section.href}`}
                            style={{ fontWeight: 700 }}
                          >
                            {section.title}
                          </Link>
                        </h4>
                      )}
                    </div>
                  ))}
                </div>
                <nav className="list">
                  <ul>
                    <li>
                      <ul className="sub">
                        <li>
                          <Link to="/cs" style={{ fontWeight: 500 }}>
                            고객 지원
                          </Link>
                        </li>
                        <li>
                          <Link to="/store" style={{ fontWeight: 500 }}>
                            입점 센터
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <hr />
                <nav className="list">
                  <ul>
                    <li style={{ paddingTop: '0px' }}>
                      <ul className="sub">
                        <li>
                          <Link
                            to="https://my.mallpie.kr/"
                            style={{ fontWeight: 500 }}
                          >
                            마이몰 관리자
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://partner.mallpie.kr"
                            style={{ fontWeight: 500 }}
                          >
                            입점사 관리자
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://partner.mallpie.biz/"
                            style={{ fontWeight: 500 }}
                          >
                            복지몰 관리자
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <div className="info">
                  {user && (
                    <div className="user-box">
                      <div className="user">
                        <Link to="/user/mypage/mymall">
                          <span>{user?.userNm}님</span>
                          <ArrowLeft
                            width={24}
                            height={24}
                            stroke={mainColor}
                          />
                        </Link>
                      </div>
                      <div className="logout" onClick={onLogout}>
                        로그아웃
                      </div>
                    </div>
                  )}

                  <a
                    href="https://my.mallpie.kr"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'block' }}
                  >
                    <Button color="black">마이몰 만들기</Button>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </Mobile>
    </>
  );
};

export default Header;
